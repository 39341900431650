@import "../master";

.card-frame-parent {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    border: 1px solid rgba(255, 67, 73, 0.5);
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.25);
    border-radius: 16px;
}

.card-frame-title {
    font-family: $common-font;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.75);
}

.card-frame-content {
    margin-top: 16px;
}

.total-number{
    color: rgba(0, 0, 0, 0.75);
    font-Family: $common-font;
    font-Weight: bold;
    font-Size: 36px;
}