@import "../master";

.input-tag {
    align-items: center;
    background: $primary-color;
    border-radius: 4px;
    color: white;
    display:  flex;
    font-weight: 600;
    padding: 4px 8px;
    white-space: nowrap;
}

.input-tag.highlight{
    background: #FFB302;
    color: black;
    box-shadow: 4px 4px 8px 0px #FFBB0080;
}

.input-tag-remove-button{
    height: 18px;
    cursor: pointer;
    padding: 8px;
}