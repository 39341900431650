@import "../master";

.navigation-button{
    height: 24px;
    width: 24px;
    padding: 6px;
}

.navigation-button:hover{
    background-color: #00000010;
    border-radius: 24px;
}