@import "../master";

.page-editor-pages-parent {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    font-weight: 700;
    color: $primary-color;
    width: 100%;
}

.page-editor-pages-title {
    font-size: medium;
}

.page-editor-page-highlight-area {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.page-editor-page {
    width: 100%;
    border: 2px solid $primary-color;
    border-radius: 1em;
    box-sizing: border-box;
}