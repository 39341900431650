@import "../master";

.tag {
    border: 2px solid $primary-color;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $primary-color;
    padding: 0.35em 0.75em;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    height: min-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
