@import "../master";

.generic-button{
    border-radius: 0.5em;
    font-family: $common-font;
    color: white;
    background: $primary-color;
    text-align: center;
    padding: 0.5em;
    width: 100%;
    box-sizing: border-box;
    font-weight: bolder;
    font-size: medium;
    cursor: pointer;
}