@import "../master";

.pdf-editor-top-bar {
    display: flex;
    justify-content: flex-end;
}

.pdf-editor-button {
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0.5em;
    border-radius: 0.25em;
    font-size: medium;
    box-shadow: unset;
    gap: 8px;
    cursor: pointer;
}

.pdf-editor-button:hover {
    opacity: 0.75;
    box-shadow: 0 4px 4px $primary-color-light;
}

.pdf-editor-pages-parent {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.filter-parent {
    position: relative;
    flex-wrap: wrap;
}

.pdf-editor-main {
    position: relative;
}

.menu-wrapper {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    position: absolute;
    top: 0;
    padding: 11px;
    overflow-y: hidden;
}

.arrange-editor {
    background: white;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    width: inherit;
    border: 1px solid #FF4349;
    border-radius: 7px;
}

.top-menu-button {
    background: #FF4349;
    border-radius: 0 0 12px 12px;
    width: 100px;
    height: 24px;
    top: 0;
    left: calc(50% - 50px);
    display: flex;
    justify-content: center;

    img {
        width: 12px;
    }

}

.arrange-editor {
    margin-top: -235px;
    transition: all .5s;

    &.show {
        margin-top: 0px;
    }
}

.apply-dark-overlay {
    transition: filter .5s;
    filter: brightness(0.25);
}

.top-menu-button {

    img {
        transition: transform .5s;
    }

    img.up {
        transform: rotate(180deg);
    }
}

div[data-rbd-droppable-id="droppable"]::-webkit-scrollbar {
    height: 3px;
}

div[data-rbd-droppable-id="droppable"]::-webkit-scrollbar-thumb {
    background: #c5c5c5;
}

.add-page-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-new-page-label {
    margin-top: 5px;
}